// project import
import { useState, useEffect } from 'react';
import { useSelector, dispatch } from 'store';
import {
  openProjectCreator,
  setConceptBeingEdited,
  setProjectMenu,
  setProjectId,
  setProjectName,
  setActiveMarket,
  setAllowRegionsReset,
  setActiveStep,
  setSegments,
  setOpenedProjectModules,
  setIsAiGenerate,
  setModelConceptToShow,
  setDisplayedResponses,
} from 'store/reducers/menu';
import { api } from '../../../../App';
import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';
import request from '../../../../utils/request';
import closeImage from '../../../../assets/images/close.png';
import { Typography, Button, OutlinedInput, MenuItem, InputLabel, FormControl, TextField, Box } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import hoverEditImage from '../../../../assets/images/hover-edit-icon.png';
import hoverDeleteImage from '../../../../assets/images/hover-delete-icon.png';

const Image = styled('img')`
  max-height: 100%;
  cursor: pointer;
  margin-left: 16px;
`;

const Img = styled('img')`
  width: 12px;
  height: 12px;
  cursor: pointer;
`;

const ModalContainer = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  border-radius: 2px;
  background-color: rgb(0, 0, 0, 0.5);
`;

const ModalHeading = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  padding: 20px 40px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
`;

const ModalContent = styled('div')`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 30px 40px;
  background-color: #fff;
`;

const ModalFooter = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 50%;
  padding: 14px 40px;
  border-top: 1px solid #ddd;
  background-color: #fff;
`;

interface IsProjectConcept {
  id: string;
  name: string;
  markets?: IsMarket[];
  metadata?: IsMetadata;
  logs: IsLog[];
}

interface IsLog {
  request: string;
  response: string;
  metadata?: IsMetadata;
  files?: any;
}

interface IsMetadata {
  createdAt: string;
  updatedAt: string;
}

interface IsMarket {
  id: string;
  name: string;
  segments?: IsSegment[];
  linkedMarkets?: string[];
  metadata: IsMarketMetadata;
}

interface IsMarketMetadata {
  createdAt: string;
}

interface IsSegment {
  id?: string;
  frontId?: string;
  name: string;
  dataSource: string;
  dataSet?: string;
  variant: string;
  percentage: number;
  value: string;
  subvariant?: string;
  subvariantValue?: string;
  subvariantPercentage?: number;
  subvariant2?: string;
  subvariant2Value?: string;
  subvariant2Percentage?: number;
  subvariant3?: string;
  subvariant3Value?: string;
  subvariant3Percentage?: number;
  countries?: string[];
  regions?: string[];
  age?: { from: number; to: number };
  sex?: string;
  income?: { from: number; to: number };
  conditions?: any;
  surveyConditions?: any;
  category?: string;
  group?: string;
  group2?: string;
  group3?: string;
  locked?: boolean;
  valuesIds?: string[];
  behaviour?: string;
}

const moduleNames = ['Size', 'Articulate', 'Test', 'Model'];
const modelConcepts = [
  'Fantasy Football Instant',
  'Slang Crossword',
  'The Claw Machine',
  'NHL Playmaker',
  'Instant Greeting',
  'Fire Bucks',
  'Triumph Combo',
  'What Did I Win?',
  'The Human Claw',
  'Ride or Die',
  'Cash Grab',
  'Monthly Luck Box',
  'Millionaire Puzzle',
  'Wonka Box',
  'Quest of Chance',
  '$100 Ticket'
];

const modelConcepts2 = [
'What did I win?', 'Instant Greeting', 'Fire Bucks', 'Fantasy Football', 'Slang Crossword'
];



// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
  const [activeModal, setActiveModal] = useState<string>('');
  const [projectModules, setProjectModules] = useState<string[]>([]);
  const [chosenModalMarketName, setChosenModalMarketName] = useState<string>('');
  const [newConceptButtonLock, setNewConceptButtonLock] = useState<boolean>(false);
  const [chosenExistingMarketId, setChosenExistingMarketId] = useState<string>('');
  const [nameEdit, setNameEdit] = useState<string>('');
  const [nameEditTarget, setNameEditTarget] = useState<string[]>(['', '']); // concept/market, id

  const menu = useSelector((state) => state.menu);
  const propelAuth = useSelector((state) => state.propelAuth);
  const {
    projectCreator,
    projectMenu,
    conceptBeingEdited,
    projectId,
    projectName,
    isSizeWelcome,
    allMarkets,
    activeMarket,
    activeStep,
    isArticulateWelcome,
    displayedResponses,
    segments,
  } = menu;
  const { token } = propelAuth;

  const [hoveredConcept, setHoveredConcept] = useState<string>('');

  const projectData = JSON.stringify({
    name: projectName,
    modules: projectModules
  });

  useEffect(() => {
    // project recently created
    if (projectId && projectName) createNewConcept();
  }, [projectId]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectName(event.target.value));
  };

  const handleModulesChange = (e: SelectChangeEvent<typeof projectModules>) => {
    const {
      target: { value }
    } = e;

    if (typeof value === 'string') return setProjectModules(value.split(','));

    if (value[value.length - 1] === 'All modules') setProjectModules(moduleNames);
    else setProjectModules(value);
  };

  const handleModalMarketChange = (e: SelectChangeEvent<typeof chosenModalMarketName>) => {
    setChosenModalMarketName(e.target.value);
  };

  const handleProjectModalOpenClick = () => {
    setActiveModal('New Project');
  };

  const handleProjectModalCloseClick = () => {
    setActiveModal('');
    dispatch(setProjectName(''));
    setProjectModules([]);
  };

  const handleCreateProjectConfirmClick = () => {
    request(
      `${api}/datahub/project`,
      'POST',
      {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      projectData
    ).then((res) => {
      console.log('project POST', res);
      return dispatch(setProjectId(res.id));
    });
  };

  const handleExistingModalMenuClick = (e: any) => {
    setChosenExistingMarketId(e.target.dataset.id);
  };

  const handleSelectModalConfirmClick = () => {
    const chosenExistingMarket = allMarkets.find((market) => market.id === chosenExistingMarketId);
    if (!chosenExistingMarket) return console.log('chosen existing marget not found');

    let linkedMarkets = [];
    if (chosenExistingMarket.linkedMarkets) linkedMarkets = [chosenExistingMarketId, ...chosenExistingMarket.linkedMarkets];
    else linkedMarkets = [chosenExistingMarketId];

    createNewMarket(linkedMarkets);
    setActiveModal('');
  };

  const handleNewConceptClick = () => {
    setNewConceptButtonLock(true);
    createNewConcept(Boolean(!activeStep));
  };

  const handleNewMarketClick = () => {
    createNewMarket();
  };

  const handleExistingMarketClick = () => {
    setActiveModal('Assign existing markets');
  };

  const handleMarketClick = (e: any) => {
    const dataset = e.target.dataset;
    const foundConcept = projectMenu.find((concept: IsProjectConcept) => concept.id === dataset.concept);

    if (!foundConcept || !foundConcept.markets || dataset.delete) return console.log('not found');

    dispatch(setActiveStep(0));

    request(`${api}/datahub/concept/${dataset.concept}`, 'GET', {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }).then((res) => {
      console.log('index concept GET', res);
      const foundMarket = res.markets.find((market: IsMarket) => market.id === dataset.market);
      const { id, name, markets, metadata, logs, files } = res;

      dispatch(setAllowRegionsReset(false));
      dispatch(
        setProjectMenu(
          projectMenu.map((concept) =>
            concept.id === dataset.concept
              ? {
                  id,
                  name,
                  logs,
                  markets: markets
                    .map((market: IsMarket) => ({
                      id: market.id,
                      name: market.name,
                      metadata: market.metadata,
                      segments: market.segments
                        ? market.segments.map((segment) => ({
                            name: segment.name,
                            conditions: segment.conditions,
                            surveyConditions: segment.surveyConditions
                          }))
                        : null
                    }))
                    .sort(
                      (a: IsMarket, b: IsMarket) => new Date(a.metadata!.createdAt).valueOf() - new Date(b.metadata!.createdAt).valueOf()
                    ),
                  metadata: { createdAt: metadata.createdAt, updatedAt: metadata.updatedAt }
                }
              : concept
          )
        )
      );
      dispatch(
        setConceptBeingEdited({
          id,
          name,
          markets: markets
            .map((market: IsMarket) => ({
              id: market.id,
              name: market.name,
              metadata: { createdAt: market.metadata?.createdAt },
              segments: market.segments
                ? market.segments.map((segment) => ({
                    name: segment.name,
                    conditions: segment.conditions,
                    surveyConditions: segment.surveyConditions
                  }))
                : null
            }))
            .sort((a: IsMarket, b: IsMarket) => new Date(a.metadata!.createdAt).valueOf() - new Date(b.metadata!.createdAt).valueOf()),
          metadata: { createdAt: metadata.createdAt, updatedAt: metadata.updatedAt },
          logs,
          files
        })
      );

      if (foundMarket) dispatch(setActiveMarket(foundMarket));
      else dispatch(setActiveMarket({ id: '', name: '', linkedMarkets: [], metadata: { createdAt: '' } }));
      
      dispatch(setSegments(foundMarket.segments[0] ? foundMarket.segments[0].surveyConditions : []));
    });
  };

  const handleConceptClick = (e: any) => {
    const dataset = e.target.dataset;
    if (dataset.market) return;
    const foundConcept = projectMenu.find((concept) => concept.id === dataset.concept);
    if (!foundConcept || !foundConcept.markets) return console.log('not found');
    request(`${api}/datahub/concept/${dataset.concept}`, 'GET', {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }).then((res) => {
      const { id, name, markets, metadata, logs } = res;
      const m = markets.sort(
        (a: IsMarket, b: IsMarket) => new Date(a.metadata!.createdAt).valueOf() - new Date(b.metadata!.createdAt).valueOf()
      );
      console.log('logs', logs);
      const logsLength = logs.length;
      const foundDisplayedResponses = displayedResponses.find((response) => response.concept === conceptBeingEdited.id);
      const sortedLogs = logs.sort((a: IsLog, b: IsLog) => new Date(a.metadata!.createdAt).valueOf() - new Date(b.metadata!.createdAt).valueOf());
      const mappedSortedLogs = sortedLogs.map((log: IsLog) => log.response);

      dispatch(setIsAiGenerate(false));
      dispatch(
        setProjectMenu(
          projectMenu.map((concept) =>
            concept.id === dataset.concept
              ? {
                  id,
                  name,
                  markets: m,
                  metadata,
                  logs: foundDisplayedResponses ? sortedLogs : logs
                }
              : concept
          )
        )
      );
      dispatch(
        setConceptBeingEdited({
          id,
          name,
          markets,
          metadata: { createdAt: metadata.createdAt, updatedAt: metadata.updatedAt },
          logs: foundDisplayedResponses ? sortedLogs : logs
        })
      );
      console.log(logsLength)
      if (logsLength > 5) {
        if (foundDisplayedResponses) dispatch(setDisplayedResponses(displayedResponses.map((response) => response.concept === id ? {concept: response.concept, responses: mappedSortedLogs} : response)));
        else dispatch(setDisplayedResponses([...displayedResponses, {concept: conceptBeingEdited.id, responses: logs.map((log: IsLog) => log.response).reverse()}]))
      }  
      else dispatch(setDisplayedResponses(displayedResponses.map((response) => response.concept === conceptBeingEdited.id ? {concept: response.concept, responses: []} : response)));

      if (!activeStep && markets.length) dispatch(setActiveMarket(markets[0]));
      else dispatch(setActiveMarket({ id: '', name: '', linkedMarkets: [], metadata: { createdAt: '' } }));
    });
  };

  const handleConceptNameChangeClick = (e: any) => {
    const targetConcept = e.target.dataset.concept;
    const foundConcept = projectMenu.find((concept) => concept.id === targetConcept);

    setNameEditTarget(['concept', targetConcept]);
    dispatch(setConceptBeingEdited(foundConcept));
  };

  const handleMarketNameChangeClick = (e: any) => {
    setNameEditTarget(['market', e.target.dataset.market]);
  };

  const handleNameEditInput = (e: any) => {
    setNameEdit(e.target.textContent);
  };

  const handleConceptNameChangeConfirmClick = () => {
    const conceptData = JSON.stringify({
      ...conceptBeingEdited,
      name: conceptBeingEdited.id === nameEditTarget[1] ? nameEdit : conceptBeingEdited.name
    });

    request(
      `${api}/datahub/concept/${conceptBeingEdited.id}`,
      'PATCH',
      {
        'Content-Type': 'application/merge-patch+json',
        Authorization: 'Bearer ' + token
      },
      conceptData
    ).then((res) => {
      if (res.id) {
        dispatch(
          setConceptBeingEdited({
            ...conceptBeingEdited,
            name: conceptBeingEdited.id === nameEditTarget[1] ? nameEdit : conceptBeingEdited.name
          })
        );
        dispatch(
          setProjectMenu(
            projectMenu.map((concept) =>
              concept.id === conceptBeingEdited.id
                ? {
                    ...conceptBeingEdited,
                    name: conceptBeingEdited.id === nameEditTarget[1] ? nameEdit : conceptBeingEdited.name
                  }
                : concept
            )
          )
        );
        setNameEdit('');
        setNameEditTarget(['', '']);
      }
    });
  };

  const handleMarketNameChangeConfirmClick = () => {
    const conceptData = JSON.stringify({
      ...conceptBeingEdited,
      markets: conceptBeingEdited.markets
        ? conceptBeingEdited.markets.map((market: IsMarket) => ({
            ...market,
            name: market.id === nameEditTarget[1] ? nameEdit : market.name,
            id: market.id,
            linkedMarkets: market.linkedMarkets,
            metadata: market.metadata,
            segments: market.segments
          }))
        : []
    });

    request(
      `${api}/datahub/concept/${conceptBeingEdited.id}`,
      'PATCH',
      {
        'Content-Type': 'application/merge-patch+json',
        Authorization: 'Bearer ' + token
      },
      conceptData
    ).then((res) => {
      if (res.id) {
        dispatch(setActiveMarket({ ...activeMarket, name: nameEdit }));
        dispatch(
          setConceptBeingEdited({
            id: conceptBeingEdited.id,
            name: conceptBeingEdited.name,
            logs: conceptBeingEdited.logs,
            metadata: { createdAt: conceptBeingEdited.metadata?.createdAt },
            markets: conceptBeingEdited.markets!.map((market: IsMarket) => ({
              ...market,
              name: market.id === activeMarket.id ? nameEdit : market.name
            }))
          })
        );
        dispatch(
          setProjectMenu(
            projectMenu.map((concept) =>
              concept.id === conceptBeingEdited.id
                ? {
                    ...conceptBeingEdited,
                    markets: conceptBeingEdited.markets!.map((market) => ({
                      ...market,
                      name: market.id === activeMarket.id ? nameEdit : market.name
                    }))
                  }
                : concept
            )
          )
        );
        setNameEdit('');
        setNameEditTarget(['', '']);
      }
    });
  };

  const handleConceptDelete = (e: any) => {
    const dataset = e.target.dataset;
    request(`${api}/datahub/concept/${dataset.concept}`, 'DELETE', {
      Authorization: 'Bearer ' + token
    }).then(() => {
      dispatch(setProjectMenu(projectMenu.filter((concept) => concept.id !== dataset.concept)));
    });
  };

  const handleMarketDelete = (e: any) => {
    const dataset = e.target.dataset;
    const conceptId = dataset.concept;
    const foundConcept = projectMenu.find((concept) => concept.id === conceptId);

    if (!foundConcept) return console.log('err');

    const conceptData = JSON.stringify({
      ...foundConcept,
      markets: foundConcept.markets ? foundConcept.markets.filter((market: IsMarket) => market.id !== dataset.market) : []
    });

    request(
      `${api}/datahub/concept/${dataset.concept}`,
      'PATCH',
      {
        'Content-Type': 'application/merge-patch+json',
        Authorization: 'Bearer ' + token
      },
      conceptData
    ).then(() => {
      dispatch(
        setProjectMenu(
          projectMenu.map((concept) =>
            concept.id === conceptId
              ? {
                  ...concept,
                  markets: foundConcept.markets!.filter((market: IsMarket) => market.id !== dataset.market)
                }
              : concept
          )
        )
      );

      if (foundConcept.id === conceptBeingEdited.id) {
        dispatch(setConceptBeingEdited({
          ...conceptBeingEdited,
          markets: conceptBeingEdited.markets!.filter((market: IsMarket) => market.id !== dataset.market)
        }))
      }

      if (activeMarket.id === dataset.market) dispatch(setActiveMarket({ id: '', name: '', linkedMarkets: [], metadata: { createdAt: '' } }))
    });
  };

  const createNewConcept = (withMarket?: boolean) => {
    let nameCounter = 1;
    let newName = '';
     
    const getNewConceptName = () => {
      const conceptName = `Concept ${nameCounter}`;
      if (projectMenu.some((c) => c.name === conceptName)) {
        nameCounter++;
        getNewConceptName();
      } else newName = conceptName;
    }
    
    getNewConceptName();

    const brandNewMarket = {
      name: `${newName} Market ${allMarkets.length ? allMarkets.length + 1 : 'X'}`,
      segments: [
        {
          name: 'Initial Segment',
          conditions: [],
          surveyConditions: []
        }
      ]
    };

    const conceptData = JSON.stringify({
      name: newName,
      project: projectId,
      markets: withMarket ? [brandNewMarket] : [],
      logs: []
    });

    return request(
      `${api}/datahub/concept`,
      'POST',
      {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      conceptData
    ).then((resp) => {
      console.log('concept POST', resp);

      request(`${api}/datahub/concept/${resp.id}`, 'GET', {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }).then((res) => {
        console.log('index concept POST GET', res);

        const { id, name, markets, metadata, logs, files } = res;

        dispatch(
          setProjectMenu([
            ...projectMenu,
            { id, name, markets, metadata: { createdAt: metadata.createdAt, updatedAt: metadata.updatedAt }, logs, files }
          ])
        );
        dispatch(
          setConceptBeingEdited({
            id,
            name,
            markets,
            metadata: { createdAt: metadata.createdAt, updatedAt: metadata.updatedAt },
            logs,
            files
          })
        );

        if (markets[0]) dispatch(setActiveMarket(markets[0]));
        else if (isSizeWelcome) dispatch(setOpenedProjectModules(projectModules));
      });

      if (!projectCreator) {
        setActiveModal('');
        dispatch(openProjectCreator(true));
      }

      setTimeout(() => setNewConceptButtonLock(false), 1000);
    });
  };

  const createNewMarket = (linkedMarketsIds?: string[]) => {
    const foundSelectedExistingMarket = allMarkets.find((market) => market.id === chosenExistingMarketId);
    const brandNewMarket = {
      name: `${conceptBeingEdited.name} Market ${allMarkets.length ? allMarkets.length + 1 : 'X'}`,
      linkedMarkets: [],
      segments: [
        {
          name: 'Initial Segment',
          conditions: [],
          surveyConditions: []
        }
      ]
    };

    const newMarket =
      linkedMarketsIds && foundSelectedExistingMarket
        ? {
            id: null,
            name: foundSelectedExistingMarket.name,
            linkedMarkets: foundSelectedExistingMarket.linkedMarkets,
            segments: foundSelectedExistingMarket.segments
              ? foundSelectedExistingMarket.segments.map((segment) => ({ conditions: segment.conditions, name: segment.name, surveyConditions: segment.surveyConditions }))
              : []
          }
        : brandNewMarket;
    if (linkedMarketsIds) console.log('new existing market', newMarket);
    else console.log('not existing');
    const conceptData = JSON.stringify({
      name: conceptBeingEdited.name,
      logs: conceptBeingEdited.logs,
      markets: conceptBeingEdited.markets
        ? [
            ...conceptBeingEdited.markets.map((market: IsMarket) => ({
              id: market.id,
              name: market.name,
              linkedMarkets: market.linkedMarkets,
              metadata: market.metadata,
              segments: market.segments
                ? market.segments.map((segment) => ({ id: segment.id, name: segment.name, conditions: segment.conditions, surveyConditions: segment.surveyConditions }))
                : []
            })),
            newMarket
          ]
        : [newMarket]
    });

    request(
      `${api}/datahub/concept/${conceptBeingEdited.id}`,
      'PUT',
      {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      conceptData
    ).then((res) => {
      if (res) {
        console.log('new market click PUT', res);
        request(`${api}/datahub/concept/${conceptBeingEdited.id}`, 'GET', {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }).then((r) => {
          console.log('get after put', r);
          const allMarketIds = allMarkets.map((market) => market.id);
          const foundNewMarket = r.markets.find((market: IsMarket) => !allMarketIds.includes(market.id));

          dispatch(setActiveMarket(foundNewMarket));
          dispatch(
            setConceptBeingEdited({
              id: conceptBeingEdited.id,
              name: conceptBeingEdited.name,
              metadata: { createdAt: conceptBeingEdited.metadata?.createdAt },
              logs: conceptBeingEdited.logs,
              markets: conceptBeingEdited.markets
                ? [
                    ...conceptBeingEdited.markets,
                    {
                      id: foundNewMarket.id,
                      name: foundNewMarket.name,
                      linkedMarkets: foundNewMarket.linkedMarkets,
                      metadata: {
                        createdAt: foundNewMarket.metadata.createdAt
                      },
                      segments: foundNewMarket.segments.map((segment: IsSegment) => ({
                        id: segment.id,
                        name: segment.name,
                        conditions: segment.conditions
                      }))
                    }
                  ]
                : []
            })
          );
          dispatch(
            setProjectMenu(
              projectMenu.map((concept) =>
                concept.id === r.id
                  ? concept.markets
                    ? {
                        ...concept,
                        markets: [
                          ...concept.markets,
                          {
                            id: foundNewMarket.id,
                            name: foundNewMarket.name,
                            linkedMarkets: foundNewMarket.linkedMarkets,
                            segments: foundNewMarket.segments.map((segment: IsSegment) => ({
                              id: segment.id,
                              name: segment.name,
                              conditions: segment.conditions,
                              surveyConditions: segment.surveyConditions
                            })),
                            metadata: foundNewMarket.metadata
                          }
                        ]
                      }
                    : []
                  : concept
              )
            )
          );
          dispatch(setSegments([])) // check if it's okay for creating existing market
        });
      }
    });
  };

  const handleMouseEnter = (e: any) => {
    const t = e.target;
    setHoveredConcept(t.id);
  };

  const handleMouseLeave = (e: any) => {
    const t = e.target;
    if (hoveredConcept === t.id) setHoveredConcept('');
  };

  const handleModelConceptClick = (e: any) => {
    const t = e.target;
    dispatch(setModelConceptToShow(Number(t.id - 1)));
    window.scrollTo(0, 0);
  };

  return (
    <SimpleBar
      sx={{
        '& .simplebar-content': {
          display: projectCreator && (isSizeWelcome || isArticulateWelcome) && activeStep < 2 ? 'none' : 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', padding: '0 28px' }}>
        <Button
          onClick={projectCreator ? handleNewConceptClick : handleProjectModalOpenClick}
          variant="contained"
          disabled={newConceptButtonLock}
          sx={{ marginTop: '25px' }}
        >
          {projectCreator ? 'New Concept' : 'CREATE NEW PROJECT'}
        </Button>
        {projectCreator ? (
          <>
            <Typography variant="h6" sx={{ marginTop: activeStep !== 2 ? '75px' : '51px', color: '#8C8C8C', visibility: activeStep !== 2 ? 'visible' : 'hidden' }}>
              Concepts
            </Typography>
            {activeStep < 2 ? (
              <Box>
                {projectMenu.length
                  ? projectMenu.map((concept: IsProjectConcept) => (
                      <Box key={concept.id + concept.name} sx={{ marginTop: '20px' }} onClick={handleConceptClick}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            cursor: 'pointer',
                            transition: 'color .3s ease-out'
                          }}
                          data-concept={concept.id}
                          className="concept"
                        >
                          <Typography
                            variant="h6"
                            data-concept={concept.id}
                            contentEditable={nameEditTarget[1] === concept.id}
                            onInput={handleNameEditInput}
                            suppressContentEditableWarning={true}
                            sx={{
                              padding: '4px 4px 4px 10px',
                              minWidth: '30px',
                              minHeight: '34px',
                              border: `2px solid ${nameEditTarget[1] === concept.id ? '#494DC8' : 'transparent'}`,
                              outline: 'none',
                              whiteSpace: 'break-spaces',
                              fontWeight: conceptBeingEdited.id === concept.id ? '600' : '400'
                            }}
                          >
                            {concept.name}
                          </Typography>
                          {!nameEditTarget[0] ? (
                            <Box sx={{ display: 'none', height: '14px', alignItems: 'center' }}>
                              <Image src={hoverEditImage} data-concept={concept.id} onClick={handleConceptNameChangeClick} />
                              <Image src={hoverDeleteImage} data-concept={concept.id} onClick={handleConceptDelete} />
                            </Box>
                          ) : (
                            <Button
                              onClick={handleConceptNameChangeConfirmClick}
                              variant="contained"
                              disabled={!nameEdit.length}
                              sx={{
                                display: nameEditTarget[1] === concept.id ? 'block' : 'none',
                                minWidth: '26px',
                                width: '26px',
                                height: '26px',
                                marginLeft: '10px',
                                padding: '2px 4px',
                                position: 'relative',
                                zIndex: '3',
                                color: !nameEdit.length ? 'crimson' : 'white'
                              }}
                            >
                              ok
                            </Button>
                          )}
                        </Box>
                        <Box sx={{ display: !activeStep ? 'block' : 'none', marginTop: '10px' }}>
                          {concept.markets
                            ? concept.markets.map((market, i) => (
                                <Box
                                  key={market ? market.id + market.name : i}
                                  onClick={handleMarketClick}
                                  data-concept={concept.id}
                                  data-market={market ? market.id : null}
                                  className={
                                    activeMarket && market && market.id && activeMarket.id === market.id && conceptBeingEdited.markets && conceptBeingEdited.markets.length ? 'ilt-active market' : 'market'
                                  }
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingLeft: '20px',
                                    minHeight: '60px',
                                    cursor: 'pointer',
                                    transition: 'color .3s ease-out'
                                  }}
                                >
                                  <Typography
                                    data-concept={concept.id}
                                    data-market={market ? market.id : null}
                                    variant="h6"
                                    contentEditable={nameEditTarget[1] === market.id}
                                    suppressContentEditableWarning={true}
                                    onInput={handleNameEditInput}
                                    sx={{
                                      padding: '4px',
                                      border: `2px solid ${nameEditTarget[1] === market.id ? '#494DC8' : 'transparent'}`,
                                      outline: 'none',
                                      minWidth: '30px',
                                      minHeight: '34px',
                                      whiteSpace: 'break-spaces'
                                    }}
                                  >
                                    {market ? market.name : 'Market'}
                                  </Typography>
                                  {!nameEditTarget[0] ? (
                                    <Box
                                      sx={{
                                        display: 'none',
                                        height: '14px',
                                        alignItems: 'center',
                                        position: 'relative',
                                        zIndex: '2'
                                      }}
                                    >
                                      <Image
                                        src={hoverEditImage}
                                        data-market={market ? market.id : null}
                                        onClick={handleMarketNameChangeClick}
                                      />
                                      <Image
                                        src={hoverDeleteImage}
                                        data-concept={concept.id}
                                        data-market={market ? market.id : null}
                                        data-delete={true}
                                        onClick={handleMarketDelete}
                                      />
                                    </Box>
                                  ) : (
                                    <Button
                                      onClick={handleMarketNameChangeConfirmClick}
                                      variant="contained"
                                      disabled={!nameEdit.length}
                                      sx={{
                                        display: nameEditTarget[1] === market.id ? 'block' : 'none',
                                        minWidth: '26px',
                                        width: '26px',
                                        height: '26px',
                                        marginLeft: '10px',
                                        padding: '2px 4px',
                                        position: 'relative',
                                        zIndex: '3',
                                        color: !nameEdit.length ? 'crimson' : '#d9d9d9'
                                      }}
                                    >
                                      ok
                                    </Button>
                                  )}
                                </Box>
                              ))
                            : null}
                        </Box>
                      </Box>
                    ))
                  : null}
              </Box>
            ) : (activeStep === 3 ? 
              <Box>
                {modelConcepts2.map((concept, i) => (
                  <Typography
                    onClick={handleModelConceptClick}
                    className={String(i + 1) === hoveredConcept ? 'ilt-active market' : 'market'}
                    id={String(i + 1)}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    key={concept}
                    variant="h6"
                    ml={1}
                    sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', height: '44px' }}
                  >
                    {concept}
                  </Typography>
                ))}
              </Box> :
              <Box>
                <Typography
                  className='ilt-active market'
                    key='build survey'
                    variant="h6"
                    ml={1}
                    sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', height: '44px' }}
                  >
                    Build Survey
                  </Typography>
                  <Typography
                    key='build audience'
                    variant="h6"
                    ml={1}
                    sx={{ display: 'flex', alignItems: 'center',  height: '44px', color: '#8C8C8C' }}
                  >
                    Build Audience
                  </Typography>
                  <Typography
                    key='launch'
                    variant="h6"
                    ml={1}
                    sx={{ display: 'flex', alignItems: 'center',  height: '44px', color: '#8C8C8C' }}
                  >
                    Launch
                  </Typography>
              </Box>

            )}
            {!activeStep ? (
              <>
                <Button sx={{ marginTop: '50px' }} onClick={handleNewMarketClick} variant="outlined" disabled={isSizeWelcome}>
                  ADD NEW MARKET
                </Button>
                <Button sx={{ marginTop: '10px' }} onClick={handleExistingMarketClick} disabled={isSizeWelcome}>
                  ADD EXISTING MARKET
                </Button>{' '}
              </>
            ) : (activeStep === 3 ? 
              <Button href='/Propel - ILC Lottery Concept Model.xlsx' download sx={{ display: 'none', marginTop: '50px' }} variant="outlined">
              Download Model
            </Button>
            : null)}
          </>
        ) : null}
      </Box>
      <Navigation />
      {activeModal ? (
        <ModalContainer>
          <ModalHeading>
            <Typography variant="h4">{activeModal}</Typography>
            <Img src={closeImage} alt="close" onClick={handleProjectModalCloseClick} />
          </ModalHeading>
          <ModalContent>
            {activeModal === 'New Project' ? (
              <>
                <Typography variant="h6" fontWeight={600} pb={1}>
                  Name your project
                </Typography>
                <FormControl sx={{ width: '100%' }}>
                  <TextField label="Select name" id="modal-project" sx={{ marginBottom: '20px' }} onChange={handleNameChange}></TextField>
                </FormControl>
                <Typography variant="h6" fontWeight={600} pb={1}>
                  What do you want to do
                </Typography>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id="modal-modules">Select modules</InputLabel>
                  <Select
                    id="modal-modules"
                    multiple={true}
                    value={projectModules}
                    onChange={handleModulesChange}
                    input={<OutlinedInput />}
                  >
                    <MenuItem value="All modules" sx={{ fontWeight: '600' }}>
                      All modules
                    </MenuItem>
                    {moduleNames.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            ) : (
              <>
                <Typography variant="h6" fontWeight={600} pb={1}>
                  Select from existing markets in this project
                </Typography>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id="modal-markets">Select market</InputLabel>
                  <Select id="modal-markets" value={chosenModalMarketName} onChange={handleModalMarketChange} input={<OutlinedInput />}>
                    {allMarkets.map((market: IsMarket) => (
                      <MenuItem key={market.id} value={market.name} data-id={market.id} onClick={handleExistingModalMenuClick}>
                        {market.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
          </ModalContent>
          <ModalFooter>
            {activeModal === 'New Project' ? (
              <Button variant="contained" onClick={handleCreateProjectConfirmClick} disabled={!projectName || !projectModules.length}>
                Create
              </Button>
            ) : (
              <Button variant="contained" onClick={handleSelectModalConfirmClick} disabled={!chosenModalMarketName}>
                Create
              </Button>
            )}
          </ModalFooter>
        </ModalContainer>
      ) : null}
    </SimpleBar>
  );
};

export default DrawerContent;
